import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';

class LoginForm extends React.Component {
  componentWillMount() {
    const token = localStorage.getItem('jwt');
    if (token) {
      window.location = '../';
    } else {
      this.setState({ error: '', token: '', submitDisabled: false });
    }
  }

  render() {
    return (
      <div>
        <div
          className='alert alert-danger'
          style={{ display: this.state.error ? 'block' : 'none' }}
        >
          {this.state.error}
        </div>
        <form onSubmit={this.loginSubmit.bind(this)}>
          <div className='form-group mb-lg'>
            <label>Customer Reference</label>
            <div className='input-group input-group-icon'>
              <input
                name='customerRef'
                type='text'
                className='form-control input-lg'
                maxLength={3}
                maxLength={50}
                ref={customerRef => {
                  this.customerRef = customerRef;
                }}
              />
              <span className='input-group-addon'>
                <span className='icon icon-lg'>
                  <i className='fa fa-user'></i>
                </span>
              </span>
            </div>
          </div>

          <div className='form-group mb-lg'>
            <label>Username</label>
            <div className='input-group input-group-icon'>
              <input
                name='username'
                type='text'
                className='form-control input-lg'
                maxLength={3}
                maxLength={50}
                ref={username => {
                  this.username = username;
                }}
              />
              <span className='input-group-addon'>
                <span className='icon icon-lg'>
                  <i className='fa fa-user'></i>
                </span>
              </span>
            </div>
          </div>

          <div className='form-group mb-lg'>
            <div className='clearfix'>
              <label className='pull-left'>Password</label>
              {/* <a href="pages-recover-password.html" className="pull-right">Lost Password?</a> */}
            </div>
            <div className='input-group input-group-icon'>
              <input
                name='pwd'
                type='password'
                className='form-control input-lg'
                ref={password => {
                  this.password = password;
                }}
              />
              <span className='input-group-addon'>
                <span className='icon icon-lg'>
                  <i className='fa fa-lock'></i>
                </span>
              </span>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-8'>
              {/* <div className="checkbox-custom checkbox-default">
                    <input id="RememberMe" name="rememberme" type="checkbox"/>
                    <label>Remember Me</label>
                  </div> */}
            </div>
            <div className='col-sm-4 text-right'>
              <button
                type='submit'
                className='btn btn-primary hidden-xs'
                disabled={this.state.submitDisabled}
              >
                Sign In
              </button>
              <button
                type='submit'
                className='btn btn-primary btn-block btn-lg visible-xs mt-lg'
                disabled={this.state.submitDisabled}
              >
                Sign In
              </button>
            </div>
          </div>

          {/* <span className="mt-lg mb-lg line-thru text-center text-uppercase">
                <span>or</span>
              </span>

              <p className="text-center">Don't have an account yet? <a href="pages-signup.html">Sign Up!</a></p> */}
        </form>
      </div>
    );
  }

  loginSubmit(event) {
    const self = this;
    event.preventDefault();

    this.setState({ submitDisabled: true });

    const username = this.username.value;
    const password = this.password.value;
    const customerRef = this.customerRef.value;

    if (username.trim() === '' || password.trim() === '' || customerRef.trim() === '') {
      this.setState({
        error: 'Please enter your Username, Password and Customer Reference',
        submitDisabled: false,
      });
      return;
    }

    this.setState({ error: '' });

    const head = new Headers();
    head.append('Content-Type', 'application/json');
    const json = { username, password, source: 'Website', customerRef };
    fetch('/api/auth', { method: 'POST', headers: head, body: JSON.stringify(json) })
      .then(response => {
        if (response.status !== 200) {
          self.setState({
            submitDisabled: false,
            error: `Looks like there was a problem. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          // If there's a token, get it so we can store it
          if (data.token) {
            localStorage.setItem('jwt', data.token);
            localStorage.setItem('username', data.username);
            localStorage.setItem('role', data.permissions);
            const proof = localStorage.getItem('jwt');
            if (proof) {
              if (data.permissions === 'admin') {
                window.location = '../admin/view';
              } else {
                window.location = '../';
              }
            }
          } else {
            self.setState({ error: data.message });
          }

          self.setState({ submitDisabled: false });
        });
      })
      .catch(err => {
        self.setState({ error: err, submitDisabled: false });
        // console.log('Fetch Error :-S', err);
      });
  }
}

ReactDOM.render(<LoginForm />, document.getElementById('hello'));
